import React from 'react';
import PropTypes from 'prop-types';
import { propTypes } from '../utils';

function CenteredCol({ colClassName, containerClassName, children }) {
  return (
    <div className={`container ${containerClassName || ''}`}>
      <div className="columns is-centered is-gapless">
        <div className={`column ${colClassName || ''}`}>
          {children}
        </div>
      </div>
    </div>
  );
}

CenteredCol.propTypes = {
  colClassName: PropTypes.string,
  containerClassName: PropTypes.string,
  children: propTypes.childrenPropType.isRequired,
};

CenteredCol.defaultProps = {
  colClassName: 'is-half',
  containerClassName: null,
};

export default CenteredCol;
