import React, { Fragment, useState, useReducer } from 'react';
import PropTypes from 'prop-types';
import { format } from 'date-fns-tz';
import { toast } from 'react-toastify';
import styled from 'styled-components';
import { Calendar, Marker, User } from '../icons';
import DecisionButtons from './DecisionButtons';
import { AddToCalendarButtons } from './add-to-calendar';
import { fetch } from '../utils';
import Modal from './Modal';
import StyleContext from './StyleContext';
import { UTCToLocalDate } from '../utils/calendar';

const CalendarLink = styled.a`
${({ color }) => (color && `color: ${color};`)}
`;

async function doBookIn(inviteId, slotId, onSuccess = () => { }, onError = () => { }) {
  const resp = await fetch.acceptSlot(inviteId, slotId);

  if (typeof resp === 'string' || resp.status === 'SERVICE_UNAVAILABLE') {
    onError(resp.status === 'SERVICE_UNAVAILABLE' ? resp.message : null);
  } else {
    if (global?.parent && typeof global.parent.postMessage === 'function') {
      global.parent.postMessage('bookingComplete', '*');
    }
    onSuccess(resp);
  }
}

async function doCancel(inviteId, onSuccess = () => { }, onError = () => { }) {
  const resp = await fetch.cancelSlot(inviteId);
  if (!resp || typeof resp === 'string') {
    onError(resp);
  } else {
    if (global?.parent && typeof global.parent.postMessage === 'function') {
      global.parent.postMessage('bookingCancelled', '*');
    }
    onSuccess();
  }
}

const defaultModalState = {
  isOpen: false,
  title: '',
  content: '',
  onOkay: () => { },
  hideOkay: false,
};

const OPEN_MODAL = 'OPEN_MODAL';
const CLOSE_MODAL = 'CLOSE_MODAL';

function modalReducer(state, action) {
  const { type, payload } = action;

  switch (type) {
    case OPEN_MODAL:
      return { ...payload, isOpen: true };
    case CLOSE_MODAL:
      return { ...defaultModalState };
    default:
      return state;
  }
}

function SlotDetails({
  inviteId,
  slot,
  onConfirm,
  onSlotBooked,
  onReject,
  onReschedule,
  onCancel,
  isReschedulable,
  isCancellable,
  isAccepted,
}) {
  const [bookedIn, setBookedIn] = useState(false);
  const [showCalBtns, setShowCalBtns] = useState(false);
  const [modalState, dispatchModal] = useReducer(modalReducer, defaultModalState);

  if (!slot || !inviteId) return null;

  const {
    startDateUTC,
    endDateUTC,
    location = {},
    eventDescription,
    eventName,
  } = slot;

  const { localStartDate, localEndDate } = UTCToLocalDate(startDateUTC, endDateUTC);
  const formattedStartDate = format(localStartDate, 'cccc, dd LLLL yyyy');
  const startTime = format(localStartDate, 'HH:mm');
  const endTime = format(localEndDate, 'HH:mm');

  function handleBookIn() {
    doBookIn(inviteId, slot.id, (resp) => {
      const { status } = resp;
      if (status === 'OK') {
        setBookedIn(true);
        onConfirm(true);
      } else {
        setBookedIn(false);
        onSlotBooked(status);
      }
    },
    (message) => {
      toast.error(
        message ||
          'Error booking you in to slot, please try again or contact support.'
      );
    });
  }

  function handleCancel(onSuccess) {
    doCancel(inviteId, onSuccess, (message) => {
      toast.error(
        message || 'Error cancelling slot, please try again or contact support.'
      );
    });
  }

  return (
    <Fragment>
      <section className="slot-details py-3">
        {bookedIn ? (
          <Fragment>
             <h1
              className="title is-uppercase has-text-success has-text-centered has-text-weight-light is-size-5-mobile"
            >
              Now add this to your calendar
            </h1>
            <AddToCalendarButtons slot={slot} />
          </Fragment>
        ) : (
          <Fragment>
            <div className="slot-name mb-4 has-text-weight-medium is-size-7-mobile">
              {isAccepted && (
                <Fragment>
                  <span className="icon mr-3">
                    <User width="1.5em" />
                  </span>
                  <div className="booking-item-content">{eventName}</div>
                </Fragment>
              )}
            </div>
            <div className="slot-date mb-4 has-text-weight-medium is-size-7-mobile">
              <span className="icon mr-3">
                <Calendar width="1.5em" />
              </span>
              <div className="booking-item-content">
                <p>{formattedStartDate}</p>
                <p>{`${startTime} - ${endTime}`}</p>
                {isAccepted && (
                  <Fragment>
                    <p className={showCalBtns ? 'mb-3' : ''}>
                      <StyleContext.Consumer>
                        {({ primaryColour }) => (
                          <CalendarLink
                            color={primaryColour}
                            href="#addtocal"
                            onClick={(e) => {
                              e.preventDefault();
                              setShowCalBtns(!showCalBtns);
                            }}
                          >
                            Add to your calendar
                          </CalendarLink>
                        )}
                      </StyleContext.Consumer>
                    </p>
                    {showCalBtns && <AddToCalendarButtons slot={slot} />}
                  </Fragment>
                )}
              </div>
            </div>
            {location?.formattedAddress && (
              <div className="slot-address mb-5 has-text-weight-medium is-size-7-mobile">
                <span className="icon mr-3">
                  <Marker width="1.5em" />
                </span>
                <div className="booking-item-content">{location.formattedAddress}</div>
              </div>
            )}
            {isAccepted ? (
              <DecisionButtons
                hideConfirm={!isReschedulable}
                hideReject={!isCancellable}
                confirmText="Reschedule"
                onConfirm={() => {
                  dispatchModal({
                    type: OPEN_MODAL,
                    payload: {
                      title: 'reschedule',
                      content: (
                        <p>Rescheduling will cancel the existing booking. The slot may not be available again.</p>
                      ),
                      onOkay: () => handleCancel(onReschedule),
                      hideOkay: false,
                    },
                  });
                }}
                rejectText="Cancel Appointment"
                onReject={() => {
                  dispatchModal({
                    type: OPEN_MODAL,
                    payload: {
                      title: 'cancel',
                      content: (
                        <div className="content">
                          {isReschedulable && <p>Why not reschedule if you need a more suitable appointment.</p>}
                          {isCancellable && <p>Are you sure you want to cancel your appointment?</p>}
                          <DecisionButtons
                            hideConfirm={!isReschedulable}
                            confirmText="Reschedule instead"
                            onConfirm={() => handleCancel(onReschedule)}
                            rejectText="Yes, please cancel my appointment"
                            onReject={() => onCancel()}
                            rejectIsLink={false}
                          />
                        </div>
                      ),
                      onOkay: () => { },
                      hideOkay: true,
                    },
                  });
                }}
                rejectIsLink={false}
              />
            ) : (
              <DecisionButtons
                confirmText="Confirm Appointment"
                onConfirm={handleBookIn}
                rejectText="Select a different slot"
                onReject={onReject}
              />
            )}
            {isAccepted && !isReschedulable && !isCancellable
            && <p><strong>Sorry you no longer are able to cancel or amend your booking</strong></p>}
          </Fragment>
        )}
      </section>
      <section className="event-description content py-3">
        <div className="content">
          <h1 className="title is-uppercase has-text-weight-light is-size-5-mobile has-text-centered">
            Important Information
          </h1>
          {/* eslint-disable-next-line react/no-danger */}
          <div dangerouslySetInnerHTML={{ __html: eventDescription }} />
        </div>
      </section>
      <Modal
        title={`Are you sure you wish to ${modalState.title}?`}
        isOpen={modalState.isOpen}
        onClose={() => dispatchModal({ type: CLOSE_MODAL })}
        onOkay={modalState.onOkay}
        hideOkay={modalState.hideOkay}
      >
        {modalState.content}
      </Modal>
    </Fragment>
  );
}

SlotDetails.propTypes = {
  inviteId: PropTypes.string,
  slot: PropTypes.shape({
    id: PropTypes.string,
    startDateUTC: PropTypes.string,
    endDateUTC: PropTypes.string,
    location: PropTypes.shape({
      formattedAddress: PropTypes.string,
    }),
    eventDescription: PropTypes.string,
    eventName: PropTypes.string,
    timeZone: PropTypes.string,
  }),
  onConfirm: PropTypes.func,
  onSlotBooked: PropTypes.func,
  onReject: PropTypes.func,
  onReschedule: PropTypes.func,
  onCancel: PropTypes.func,
  isAccepted: PropTypes.bool,
  isCancellable: PropTypes.bool,
  isReschedulable: PropTypes.bool,
};

SlotDetails.defaultProps = {
  inviteId: null,
  slot: null,
  onConfirm: () => { },
  onSlotBooked: () => { },
  onReject: () => { },
  onReschedule: () => { },
  onCancel: () => { },
  isAccepted: false,
  isCancellable: false,
  isReschedulable: false,
};

export default SlotDetails;
