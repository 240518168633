import { parseISO, format, addMilliseconds, addMinutes } from "date-fns";
import { getTimezoneOffset, utcToZonedTime } from "date-fns-tz";

// https://github.com/jasonleibowitz/react-add-to-calendar-hoc/blob/master/src/lib/utils.js

// eslint-disable-next-line max-len
export const isMobile = () =>
  /Mobile|iP(hone|od|ad)|Android|BlackBerry|IEMobile/.test(
    window.navigator.userAgent || window.navigator.vendor || window.opera
  );

// eslint-disable-next-line max-len
export const isInternetExplorer = () =>
  /MSIE/.test(window.navigator.userAgent) ||
  /Trident/.test(window.navigator.userAgent);

export const UTCToLocalDate = (startDate, endDate) => {
  const localTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  const zonedDate = utcToZonedTime(startDate, localTimezone);
  const timezoneOffset = getTimezoneOffset(localTimezone, zonedDate);

  const localStartDate = addMilliseconds(parseISO(startDate), timezoneOffset);
  const localEndDate = addMilliseconds(parseISO(endDate), timezoneOffset);

  return { localStartDate, localEndDate };
};

export function googleEventSlotUrl({
  description,
  endDatetime,
  location,
  startDatetime,
  timezone,
  title,
}) {
  return `https://calendar.google.com/calendar/render?action=TEMPLATE&dates=${startDatetime}/${endDatetime}${
    timezone && `&ctz=${timezone}`
  }&location=${location}&text=${title}&details=${description}`;
}

export function eventSlotFile({
  description = "",
  // ctz = '',
  endDatetime,
  location = "",
  startDatetime,
  title = "",
}) {
  const content = [
    "BEGIN:VCALENDAR",
    "VERSION:2.0",
    "BEGIN:VEVENT",
    `URL:${document.URL}`,
    "METHOD:PUBLISH",
    `DTSTART:${startDatetime}`,
    `DTEND:${endDatetime}`,
    `SUMMARY:${title}`,
    `DESCRIPTION:${description}`,
    `LOCATION:${location}`,
    "END:VEVENT",
    "END:VCALENDAR",
  ].join("\n");

  return isMobile()
    ? encodeURI(`data:text/calendar;charset=utf8,${content}`)
    : content;
}

// eslint-disable-next-line import/prefer-default-export
export function parseSlot(slot, type = "ICAL") {
  const encodeUri = type !== "ICAL" && type !== "OUTLOOK";
  const dateTimeFormat = "yyyyMMdd'T'HHmmss";

  const {
    eventDescription,
    eventName,
    startDateUTC,
    endDateUTC,
    timeZone,
    location = {},
  } = slot;
  const { formattedAddress = "" } = location;

  const constParsedDate = UTCToLocalDate(startDateUTC, endDateUTC);
  const start = constParsedDate.localStartDate;
  const end = constParsedDate.localEndDate;

  const normalizedSlot = {
    description: encodeUri
      ? encodeURIComponent(eventDescription)
      : eventDescription,
    title: encodeUri ? encodeURIComponent(eventName) : eventName,
    location: encodeUri
      ? encodeURIComponent(formattedAddress)
      : formattedAddress,
    timezone: timeZone,
    startDatetime: format(start, dateTimeFormat),
    endDatetime: format(end, dateTimeFormat),
  };

  if (type === "GCAL") return googleEventSlotUrl(normalizedSlot);
  return eventSlotFile(normalizedSlot);
}
