const config = {
  api: {
    middlewareAPIURL: process.env.MIDDLEWARE_API_URL || 'https://ats-api.talent-funnel.com/js',
  },
  scheduling: {
    availableEvents: process.env.AVAILABLE_EVENTS || '/scheduling/calendar/view',
    slotAccept: process.env.SLOT_ACCEPT || '/scheduling/invite/accept',
    slotCancel: process.env.SLOT_CANCEL || '/scheduling/invite/cancel',
    noSlotPath: process.env.NO_SLOT || '/scheduling/invite/no-slot-report',
  },
  interviewing: {
    joinVideoMeetingPath: process.env.JOIN_VIDEO_MEETING_PATH || '/interview/video/join',
    participantCheckPath: process.env.PARTICIPANT_CHECK_PATH || '/interview/video/check/participants',
  },
};

module.exports = config;
