import React, {
  useState,
  useEffect,
  useRef,
  useCallback,
  Fragment,
} from 'react';
import PropTypes from 'prop-types';

function trackpubsToTracks(trackMap) {
  return Array.from(trackMap.values()).map((publication) => publication.track).filter((track) => track !== null);
}

function Participant({
  data,
  maxHeight,
  className,
  audioMuted,
}) {
  const isMounted = useRef();
  const videoRef = useRef();
  const audioRef = useRef();
  const [videoTracks, setVideoTracks] = useState([]);
  const [audioTracks, setAudioTracks] = useState([]);

  const subscribe = useCallback((track) => {
    if (track.kind === 'video') {
      setVideoTracks((prevState) => [...prevState, track]);
    }
    else {
      setAudioTracks((prevState) => [...prevState, track]);
    }
  }, []);

  const unsubscribe = useCallback((track) => {
    if (track.kind === 'video') {
      setVideoTracks((prevState) => prevState.filter((v) => v !== track));
    }
    else {
      setAudioTracks((prevState) => prevState.filter((a) => a !== track));
    }
  }, []);

  useEffect(() => {
    isMounted.current = true;

    const { videoTracks: vTracks, audioTracks: aTracks } = data;

    if (isMounted.current) {
      setVideoTracks(trackpubsToTracks(vTracks));
      setAudioTracks(trackpubsToTracks(aTracks));

      data.on('trackSubscribed', subscribe);
      data.on('trackUnsubscribed', unsubscribe);
    }

    return () => {
      isMounted.current = false;
      setVideoTracks([]);
      setAudioTracks([]);
      data.removeAllListeners();
    };
  }, [data, subscribe, unsubscribe]);

  useEffect(() => {
    const [videoTrack] = videoTracks;
    const [audioTrack] = audioTracks;
    if (videoTrack) videoTrack.attach(videoRef.current);
    if (audioTrack) audioTrack.attach(audioRef.current);

    return () => {
      if (videoTrack) videoTrack.detach();
      if (audioTrack) audioTrack.detach();
    };
  }, [audioTracks, videoTracks]);

  return (
    <Fragment>
      {/* eslint-disable-next-line jsx-a11y/media-has-caption */}
      <video
        ref={videoRef}
        autoPlay
        className={className}
        style={{ maxHeight }}
      />
      {/* eslint-disable-next-line jsx-a11y/media-has-caption */}
      <audio ref={audioRef} autoPlay muted={audioMuted} />
    </Fragment>
  );
}

Participant.propTypes = {
  data: PropTypes.shape(),
  maxHeight: PropTypes.string,
  className: PropTypes.string,
  audioMuted: PropTypes.bool,
};

Participant.defaultProps = {
  data: {},
  maxHeight: null,
  className: null,
  audioMuted: false,
};

export default Participant;
