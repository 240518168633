import styled from 'styled-components';

const StyledButton = styled.button`
  ${({
    isLink,
    flipColors,
    primary,
    secondary,
  }) => (isLink ? `
    ${primary && `color: ${primary} !important;`}
  ` : `
    ${primary && `border-color: ${primary};`}
    ${flipColors ? `
      ${secondary && `background-color: ${secondary};`}
      ${primary && `color: ${primary};`}
    ` : `
      ${primary && `background-color: ${primary};`}
      ${secondary && `color: ${secondary};`}
    `}
  `)}
`;

export default StyledButton;
