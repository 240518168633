import React from 'react';
import PropTypes from 'prop-types';
import { propTypes, calendar } from '../../utils';

function CalendarButton({
  className,
  children,
  parsedSlot,
}) {
  // https://github.com/jasonleibowitz/react-add-to-calendar-hoc/blob/master/src/lib/index.jsx#L48
  function handleClick() {
    if (parsedSlot) {
      if (parsedSlot.startsWith('BEGIN')) {
        const filename = 'slot';
        const blob = new Blob([parsedSlot], { type: 'text/calendar;charset=utf-8' });

        if (calendar.isInternetExplorer()) {
          window.navigator.msSaveOrOpenBlob(blob, `${filename}.ics`);
        }
        else {
          const link = document.createElement('a');
          link.href = window.URL.createObjectURL(blob);
          link.setAttribute('download', `${filename}.ics`);
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
        }
      }
      else {
        window.open(parsedSlot, '_blank');
      }
    }
  }

  return (
    <button
      type="button"
      className={`button calendar-btn is-fullwidth py-5 is-small has-text-weight-semibold ${className || ''}`}
      onClick={handleClick}
    >
      {children}
    </button>
  );
}

CalendarButton.propTypes = {
  className: PropTypes.string,
  children: propTypes.childrenPropType.isRequired,
  parsedSlot: PropTypes.string,
};

CalendarButton.defaultProps = {
  className: null,
  parsedSlot: null,
};

export default CalendarButton;
