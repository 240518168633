import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { toast } from 'react-toastify';
import { fetch } from '../utils';

async function doNoSlotReport(inviteId, onSuccess = () => { }, onError = () => { }) {
  const resp = await fetch.noSlotReport(inviteId);

  if (resp === 'SERVICE_UNAVAILABLE') {
    onError(
      'The service is down for scheduled maintaince. Please try again later.'
    );
  } else if (typeof resp === 'string') {
    onSuccess();
  } else {
    onError();
  }
}

function NoSlots({ inviteId }) {
  const [submitted, setSubmitted] = useState(false);

  return (
    <div className="has-text-centered">
      <p className="has-text-weight-semibold">
        No slots available
      </p>
      {(inviteId && !submitted) && (
        <button
          type="button"
          className="button is-link mt-2"
          onClick={() => {
            doNoSlotReport(
              inviteId,
              () => {
                toast.success('Thank you for letting us know');
                setSubmitted(true);
              },
              (message) => {
                toast.error(
                  message || 'Error submitting request, please try again'
                );
              },
            );
          }}
        >
          Let us know
        </button>
      )}
    </div>
  );
}

NoSlots.propTypes = {
  inviteId: PropTypes.string,
};

NoSlots.defaultProps = {
  inviteId: null,
};

export default NoSlots;
