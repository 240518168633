import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { parseISO, format } from 'date-fns';
import SlotItem from './SlotItem';
import NoSlots from './NoSlots';
import { UTCToLocalDate } from '../utils/calendar';

function SlotList({
  inviteId,
  dates,
  showLocation,
  onClick,
}) {
  if (!dates.length) return <NoSlots inviteId={inviteId} />;

  return dates.map(([isoDate, slots]) => (
    <Fragment key={isoDate}>
      <p className="is-uppercase has-text-weight-semibold is-size-7-mobile">
        {format(parseISO(isoDate), 'cccc, dd LLLL yyyy')}
      </p>
      <ul>
        {slots.map((slot) => {
          const { location = {} } = slot;
          const { buildingName, city } = location;
          let locationStr;
          if (showLocation) locationStr = `${buildingName}, ${city}`;


          const { localStartDate, localEndDate } = UTCToLocalDate(slot.startDateUTC, slot.endDateUTC);
          const startTime = format(localStartDate, 'HH:mm');
          const endTime = format(localEndDate, 'HH:mm');

          return (
            <li key={slot.id}>
              <SlotItem
                startTime={startTime}
                endTime={endTime}
                location={locationStr}
                onClick={() => onClick(slot)}
              />
            </li>
          );
        })}
      </ul>
      <hr className="my-3" />
    </Fragment>
  ));
}

SlotList.propTypes = {
  inviteId: PropTypes.string,
  dates: PropTypes.arrayOf(PropTypes.array),
  showLocation: PropTypes.bool,
  onClick: PropTypes.func,
};

SlotList.defaultProps = {
  inviteId: null,
  dates: [],
  showLocation: false,
  onClick: () => { },
};

export default SlotList;
