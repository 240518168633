import React from 'react';
// import PropTypes from 'prop-types';

function Loading() {
  return (
    <div className="page-loader">
      <div className="spinner">
        <div className="double-bounce1" />
        <div className="double-bounce2" />
      </div>
    </div>
  );
}

export default Loading;
