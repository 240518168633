/* eslint-disable jsx-a11y/label-has-for */
import React, {
  useState,
  Fragment,
  useEffect,
  useRef,
} from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
import queryString from 'query-string';
import Lobby from './Lobby';
import Room from './Room';
import config from '../../config/config';
import ErrorMessage from './ErrorMessage';
import Loading from '../Loading';

const { api, interviewing } = config;

// async function isMediaEnabled(onError = () => { }) {
//   try {
//     await navigator.mediaDevices.getUserMedia({ video: true, audio: true });
//   }
//   catch {
//     onError();
//   }
// }

async function getRoomDetails(roomId, candidateId, onSuccess = () => { }, onError = () => { }) {
  if (roomId && candidateId) {
    try {
      const result = await axios.get(
        `${api.middlewareAPIURL}${interviewing.joinVideoMeetingPath}?roomId=${roomId}&cId=${candidateId}`,
        { headers: { 'Content-Type': 'application/json; charset=utf-8' } },
      );

      onSuccess(result.data);
    }
    catch (error) {
      const { response = {} } = error;
      const { status } = response;
      onError(status);
    }
  }
}

function VideoChat({ location }) {
  // const [username, setUsername] = useState('');
  // const [roomName, setRoomName] = useState('');
  const [isLoading, setIsLoading] = useState(true);
  const [token, setToken] = useState();
  const [enteredRoom, setEnteredRoom] = useState(false);
  const [candidateData, setCandidateData] = useState(null);
  const [mediaEnabled, setMediaEnabled] = useState(true);
  const [errorStatus, setErrorStatus] = useState();
  const isMounted = useRef();

  const { search } = location || {};
  const { id: roomId, cId } = queryString.parse(search);

  useEffect(() => {
    isMounted.current = true;

    // isMediaEnabled(() => setMediaEnabled(false));

    return () => {
      isMounted.current = false;
    };
  }, []);

  useEffect(() => {
    getRoomDetails(roomId, cId, (resp) => {
      if (isMounted.current) {
        setToken(resp.token);
        setCandidateData(resp.candidate);
        setIsLoading(false);
      }
    }, (status) => {
      if (isMounted.current) {
        setErrorStatus(status);
        setIsLoading(false);
      }
    });
  }, [cId, roomId]);

  // const handleUsernameChange = useCallback((event) => {
  //   setUsername(event.target.value);
  // }, []);

  // const handleRoomNameChange = useCallback((event) => {
  //   setRoomName(event.target.value);
  // }, []);

  if (!roomId || !cId) {
    return (
      <ErrorMessage
        title="Meeting ID required to start interview"
        message="Please provide valid link with a meeting ID"
      />
    );
  }

  if (isLoading) return <Loading />;
  if (errorStatus) return <ErrorMessage errorStatus={errorStatus} />;

  return (
    <Fragment>
      <div className="h-100">
        {mediaEnabled ? (
          <div>
            {token && enteredRoom ? (
              <Room
                roomName=""
                token={token}
                handleLogout={() => setEnteredRoom(false)}
                // candidate={candidateData}
              />
            ) : (
              <Lobby
                roomId={roomId}
                handleSubmit={() => setEnteredRoom(true)}
                candidate={candidateData}
              />
            )}
          </div>
        ) : (
          <div className="has-text-centered content">
            <h4>The browser&apos;s camera and microphone are currently disabled</h4>
            <p>Please enable your browser&apos;s camera and microphone to use this feature</p>
          </div>
        )}
      </div>
    </Fragment>
  );
}

VideoChat.propTypes = {
  location: PropTypes.shape(),
};

VideoChat.defaultProps = {
  location: {},
};

export default VideoChat;
