import React from 'react';
import PropTypes from 'prop-types';
import { Apple, MicroSoft, Google } from '../../icons';
import CalendarButton from './CalendarButton';
import { calendar } from '../../utils';

function AddToCalendarButtons({ slot }) {
  return (
    <div className="container has-text-centered add-to-cal-btns">
      <div className="columns">
        <div className="column">
          <CalendarButton
            className="apple"
            type="ICAL"
            parsedSlot={calendar.parseSlot(slot)}
          >
            <Apple width="1rem" />
            <span className="is-hidden-mobile">iCal</span>
            <span className="is-hidden-tablet">Add to iCal</span>
          </CalendarButton>
        </div>
        <div className="column">
          <CalendarButton
            className="microsoft"
            parsedSlot={calendar.parseSlot(slot, 'OUTLOOK')}
          >
            <MicroSoft width="1rem" />
            <span className="is-hidden-mobile">Outlook</span>
            <span className="is-hidden-tablet">Add to Outlook</span>
          </CalendarButton>
        </div>
        <div className="column">
          <CalendarButton
            className="google"
            parsedSlot={calendar.parseSlot(slot, 'GCAL')}
          >
            <Google width="1rem" />
            <span className="is-hidden-mobile">Google Cal</span>
            <span className="is-hidden-tablet">Add to Google Cal</span>
          </CalendarButton>
        </div>
      </div>
    </div>
  );
}

AddToCalendarButtons.propTypes = {
  slot: PropTypes.shape(),
};

AddToCalendarButtons.defaultProps = {
  slot: {},
};

export default AddToCalendarButtons;
