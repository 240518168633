import React from 'react';
import PropTypes from 'prop-types';
import Participant from './Participant';

function LocalParticipant({ room, ...rest }) {
  if (!Object.keys(room).length) return null;
  return <Participant data={room.localParticipant} {...rest} audioMuted />;
}

LocalParticipant.propTypes = {
  room: PropTypes.shape(),
};

LocalParticipant.defaultProps = {
  room: {},
};

export default LocalParticipant;
