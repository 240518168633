import React from 'react';
import Layout from './Layout';

function Page503() {
  return (
    <Layout title="MAINTENANCE IN Progress">
      <div className="content">
        <p>
          We&rsquo;re currently performing scheduled maintenance to enhance our
          platform, we should be back shortly.
        </p>
      </div>
    </Layout>
  );
}

export default Page503;
