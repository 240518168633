import React, {
  useEffect,
  useRef,
  useState,
  useReducer,
  Fragment,
  useCallback,
} from 'react';
// import PropTypes from 'prop-types';
import { useLocation } from 'react-router-dom';
import queryString from 'query-string';
import styled from 'styled-components';
import Layout from './Layout';
import CenteredCol from './CenteredCol';
import Page404 from './Page404';
import Page503 from './Page503';
import { fetch } from '../utils';
import SlotList from './SlotList';
import SlotDetails from './SlotDetails';
import { CheckCircle, ExclCircle, ChevronUp } from '../icons';
import CancelledView from './CancelledView';
import Loading from './Loading';
import StyleContext from './StyleContext';

const HighLight = styled.section`
  ${({ isBookedIn, primary, secondary }) => (!isBookedIn && `
    ${primary && `background-color: ${primary};`}
    ${secondary && `color: ${secondary};`}
  `)}
`;

function scrollToTop() {
  const c = document.documentElement.scrollTop || document.body.scrollTop;
  if (c > 0) {
    window.requestAnimationFrame(scrollToTop);
    window.scrollTo(0, c - c / 10);
  }
}

const DEFAULT_TITLE = 'Select a slot';

const defaultAddressState = {
  address: null,
  show: false,
};

const ADDRESS_UPDATE = 'ADDRESS_UPDATE';
const ADDRESS_TOGGLE = 'ADDRESS_TOGGLE';

function addressReducer(state, action) {
  const { type, payload } = action;

  switch (type) {
    case ADDRESS_UPDATE:
      return {
        address: payload,
        show: true,
      };
    case ADDRESS_TOGGLE:
      return {
        ...state,
        show: payload,
      };
    default:
      return state;
  }
}

function BookingUI() {
  const isMounted = useRef();
  const location = useLocation();
  const [pageState, setPageState] = useState('pending');
  const [dates, setDates] = useState([]);
  const [addressState, dispatchAddress] = useReducer(addressReducer, defaultAddressState);
  const [eventName, setEventName] = useState();
  const [selectedSlot, setSelectedSlot] = useState();
  const [title, setTitle] = useState(DEFAULT_TITLE);
  const [bookedIn, setBookedIn] = useState(false);
  const [isAccepted, setIsAccepted] = useState(false);
  const [inviteCancelled, setInviteCancelled] = useState(false);
  const [izReschedulable, setIsReschedulable] = useState(false);
  const [isCancelled, setIsCancelled] = useState(false);
  const [izCancellable, setIzCancellable] = useState(false);
  const [bookedAcceptedResp, setBookedAcceptedResp] = useState();
  const [showSTT, setShowSTT] = useState(false);
  const [styleObj, setStyleObj] = useState({});

  const { search } = location;
  const { id } = queryString.parse(search);
  
  const fetchSlots = useCallback(async (inviteId, onResolve = () => { }, onReject = () => { }) => {
    const resp = await fetch.getSlots(inviteId, 'DATE');

      if (isMounted.current) {
        if (resp.status === 'SERVICE_UNAVAILABLE') {
          setPageState('error');
        } else if (typeof resp === 'string') {
          onReject();
        } else {
          const {
            status,
            slots,
            groupedSlots,
            numLocations,
            isReschedulable,
            isCancellable,
            styling = {},
          } = resp;

          if (status === 'OK' && groupedSlots) {
            const slotEntries = Object.entries(groupedSlots);
            setDates(slotEntries);

            if (slotEntries.length) {
              const [primaryEntry = {}] = slotEntries[0][1];

              const { location: loc, eventName: evtName } = primaryEntry;

              if (evtName) setEventName(evtName);

              if (loc && loc.formattedAddress && numLocations === 1) {
                dispatchAddress({
                  type: ADDRESS_UPDATE,
                  payload: loc.formattedAddress,
                });
              }
            }
          } else if (status === 'ACCEPTED' && slots && slots.length) {
            setSelectedSlot(slots[0]);
            setIsAccepted(true);
            setIsReschedulable(isReschedulable);
            setIzCancellable(isCancellable);
            setTitle('Your booking');
          } else if (status === 'REJECTED') {
            setInviteCancelled(true);
            setTitle('Sorry this invite is no longer available');
          }

          setStyleObj(styling);
          onResolve();
        }
      }
    }, []);

  useEffect(() => {
    function onScroll() {
      const { scrollY } = window;
      if (isMounted.current) setShowSTT(scrollY > 100);
    }

    window.addEventListener('scroll', onScroll);

    return () => {
      window.removeEventListener('scroll', onScroll);
    };
  }, []);

  useEffect(() => {
    isMounted.current = true;
    if (id) fetchSlots(id, () => setPageState('resolved'), () => setPageState('rejected'));
    return () => {
      isMounted.current = false;
    };
  }, [fetchSlots, id]);

  function handleSlotSelection(slotObj) {
    setSelectedSlot(slotObj);
    setTitle('Your selection');
    dispatchAddress({ type: ADDRESS_TOGGLE, payload: false });
  }

  function handleSlotRejection(refresh) {
    setSelectedSlot();
    setTitle(DEFAULT_TITLE);
    dispatchAddress({ type: ADDRESS_TOGGLE, payload: true });
    setBookedIn(false);
    setIsAccepted(false);
    setIsCancelled(false);
   
    if (id && refresh) {
      setPageState('pending');
      fetchSlots(id, () => setPageState('resolved'), () => setPageState('rejected'));
    }
  }

  function handleSlotConfirm(hasBookedIn) {
    setBookedIn(hasBookedIn);
    setTitle('Slot booked!');
    setBookedAcceptedResp();
  }

  function handleSlotBooked(state) {
    if (state === 'ALREADY_ACCEPTED') {
      setBookedAcceptedResp('You have already accepted this appointment');
    }

    if (state === 'FULLY_BOOKED') {
      setBookedAcceptedResp('Appointment fully booked');
    }

    setBookedIn(true);
  }

  if (pageState === 'pending') return <Loading />;
  if (pageState === 'error') {
    return <Page503 />;
  }
  if (!id || pageState === 'rejected') return <Page404 />;
  if (isCancelled) {
    return (
      <StyleContext.Provider value={{ ...styleObj }}>
        <CancelledView
          inviteId={id}
          colors={{ primary: styleObj.primaryColour, secondary: styleObj.secondaryColour }}
          onClick={() => handleSlotRejection(true)}
        />
      </StyleContext.Provider>
    );
  }

  return (
    <Fragment>
      <StyleContext.Provider value={{ ...styleObj }}>
        <Layout
          title={title}
          highlights={() => (
            !isAccepted && (
              <HighLight
                // eslint-disable-next-line max-len
                className={`section ${bookedIn ? `has-background-${bookedAcceptedResp ? 'warning' : 'success'} has-text-${bookedAcceptedResp ? 'grey' : 'white'}` : ''}`}
                bookedIn={bookedIn}
                primary={styleObj.primaryColour}
                secondary={styleObj.secondaryColour}
              >
                <CenteredCol>
                  {bookedIn ? (
                    <div className="has-text-centered is-size-4 is-size-6-mobile">
                      <Fragment>
                        {bookedAcceptedResp ? (
                          <Fragment>
                            <div><ExclCircle /></div>
                            <p className="mb-1">{bookedAcceptedResp}</p>
                            <button
                              type="button"
                              className="button is-small is-light"
                              onClick={handleSlotRejection}
                            >
                              Choose a different slot
                            </button>
                          </Fragment>
                        ) : (
                          <Fragment>
                            <div><CheckCircle /></div>
                            Your appointment has been booked!
                          </Fragment>
                        )}
                      </Fragment>
                    </div>
                  ) : (
                    <div className="content is-size-7-mobile py-1">
                      {eventName && <p className="has-text-weight-semibold">{eventName}</p>}
                      {addressState.show && addressState.address && <p>{addressState.address}</p>}
                    </div>
                  )}
                </CenteredCol>
              </HighLight>
            )
          )}
        >

          {selectedSlot
            && (
            <SlotDetails
              inviteId={id}
              slot={selectedSlot}
              onConfirm={handleSlotConfirm}
              onSlotBooked={handleSlotBooked}
              onReject={handleSlotRejection}
              onReschedule={() => handleSlotRejection(true)}
              onCancel={() => setIsCancelled(true)}
              isAccepted={isAccepted}
              isReschedulable={izReschedulable}
              isCancellable={izCancellable}
            />
            )}
          {!selectedSlot && !inviteCancelled
          && (
          <SlotList
            inviteId={id}
            dates={dates}
            showLocation={!addressState.show}
            onClick={handleSlotSelection}
          />
          )}
        </Layout>
      </StyleContext.Provider>
      <a
        href="#to-top"
        className={`scroll-to-top${showSTT ? ' show' : ''}`}
        onClick={(e) => {
          e.preventDefault();
          scrollToTop();
        }}
      >
        <ChevronUp />
        <span className="is-sr-only">Back to top</span>
      </a>
    </Fragment>
  );
}

BookingUI.propTypes = {

};

BookingUI.defaultProps = {

};

export default BookingUI;
