import React from 'react';
import PropTypes from 'prop-types';
import StyleContext from './StyleContext';
import StyledButton from './StyledButton';

function DecisionButtons({
  confirmText,
  rejectText,
  onConfirm,
  onReject,
  confimIsLink,
  rejectIsLink,
  hideConfirm,
  hideReject,
}) {
  const classes = [
    'button',
    'decision-btn',
    'is-uppercase',
    'is-fullwidth',
    'is-small',
    'has-text-weight-semibold',
  ];

  const confirmClasses = confimIsLink ? [...classes, 'is-white'] : [...classes, 'py-5'];
  const rejectClasses = rejectIsLink ? [...classes, 'is-white'] : [...classes, 'py-5'];

  return (
    <StyleContext.Consumer>
      {({ primaryColour, secondaryColour }) => (
        <div className="has-text-centered">
          {!hideConfirm
          && (
          <StyledButton
            type="button"
            className={`${confirmClasses.join(' ')} mb-3`}
            onClick={onConfirm}
            isLink={confimIsLink}
            primary={primaryColour}
            secondary={secondaryColour}
          >
            {confirmText}
          </StyledButton>
          )}
          {!hideReject
          && (
          <StyledButton
            type="button"
            className={rejectClasses.join(' ')}
            onClick={onReject}
            isLink={rejectIsLink}
            flipColors
            primary={primaryColour}
            secondary={secondaryColour}
          >
            {rejectText}
          </StyledButton>
          )}
        </div>
      )}
    </StyleContext.Consumer>
  );
}

DecisionButtons.propTypes = {
  confirmText: PropTypes.string.isRequired,
  rejectText: PropTypes.string.isRequired,
  onConfirm: PropTypes.func,
  onReject: PropTypes.func,
  confimIsLink: PropTypes.bool,
  rejectIsLink: PropTypes.bool,
  hideConfirm: PropTypes.bool,
  hideReject: PropTypes.bool,
};

DecisionButtons.defaultProps = {
  onConfirm: () => { },
  onReject: () => { },
  confimIsLink: false,
  rejectIsLink: true,
  hideConfirm: false,
  hideReject: false,
};

export default DecisionButtons;
