import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Marker } from '../icons';
import StyleContext from './StyleContext';

const SlotItemDiv = styled.div`
  ${({ color }) => (color && `
    .slot-item-date {
      color: ${color};
    }

    &::after {
      border-bottom-color: ${color};
      border-right-color: ${color};
    }
  `)}
`;

function SlotItem({
  startTime,
  endTime,
  location,
  onClick,
}) {
  return (
    <Fragment>
      <StyleContext.Consumer>
        {({ primaryColour }) => (
          <SlotItemDiv
            color={primaryColour}
            className="slot-item"
            role="button"
            tabIndex={0}
            onClick={onClick}
            onKeyUp={onClick}
          >
            <div className="slot-item-date is-size-4 has-text-weight-light pt-2">
              {`${startTime} - ${endTime}`}
            </div>
          </SlotItemDiv>
        )}
      </StyleContext.Consumer>
      {location && (
        <p className="is-size-7 has-text-grey-light">
          <span className="icon">
            <Marker width="0.75em" />
          </span>
          {location}
        </p>
      )}
    </Fragment>
  );
}

SlotItem.propTypes = {
  startTime: PropTypes.string.isRequired,
  endTime: PropTypes.string.isRequired,
  location: PropTypes.string,
  onClick: PropTypes.func,
};

SlotItem.defaultProps = {
  location: null,
  onClick: () => { },
};

export default SlotItem;
