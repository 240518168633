import React from 'react';
import PropTypes from 'prop-types';
import CenteredCol from '../CenteredCol';

function ErrorMessage({ errorStatus, title, message }) {
  let errorTitle = title;
  let errorMessage = message;

  if (errorStatus === 500 || errorStatus === 501) {
    errorTitle = 'Something appears to have gone wrong!';
    errorMessage = 'Rest assured we\'re working hard to fix it. Please try again later.';
  }

  if (errorStatus === 412) {
    errorTitle = 'Meeting is not available yet';
    errorMessage = 'It will become available 15 minutes before the scheduled start time. Please try again later';
  }

  return (
    <CenteredCol colClassName="content has-text-centered" containerClassName="mt-4">
      <h2>{errorTitle}</h2>
      <p>{errorMessage}</p>
      <button
        type="button"
        className="button is-info"
        onClick={() => window.location.reload()}
      >
        Refresh
      </button>
    </CenteredCol>
  );
}

ErrorMessage.propTypes = {
  errorStatus: PropTypes.number,
  title: PropTypes.string,
  message: PropTypes.string,
};

ErrorMessage.defaultProps = {
  errorStatus: 0,
  title: 'Your attendance was not confirmed',
  message: 'Once confirmed, the video will be available',
};

export default ErrorMessage;
