import 'react-app-polyfill/ie11'; // For IE 11 support
import 'react-app-polyfill/stable'; // Stable language feature for targeted browsers
import React, { Fragment } from 'react';
import { BrowserRouter, Switch, Route } from 'react-router-dom';
import { toast, ToastContainer, Slide } from 'react-toastify';
import { BookingUI, Page404 } from './components';
import './scss/styles.scss';
import VideoChat from './components/video-conferencing/VideoChat';

function App() {
  return (
    <Fragment>
      <BrowserRouter>
        <Switch>
          <Route path="/invite"><BookingUI /></Route>
          <Route path="/interview" component={VideoChat} />
          <Route path="*"><Page404 /></Route>
        </Switch>
      </BrowserRouter>
      <ToastContainer
        transition={Slide}
        hideProgressBar
        position={toast.POSITION.TOP_CENTER}
      />
    </Fragment>
  );
}

export default App;
