import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { propTypes } from '../utils';
import CenteredCol from './CenteredCol';
import StyleContext from './StyleContext';

const PageEnd = styled.div`
  ${({ hero }) => (hero && 'box-shadow: inset 0px -15px 60px 12px rgba(0,0,0,.5);')}
  ${({ hasLogo }) => (hasLogo && `
    .hero-body {
      padding: 0.25rem;

      @media screen and (max-width: 1023px) {
        padding: 0.5rem 1.5rem;
      }
    }
  `)}
`;

const Heading = styled.h1`
  white-space: nowrap;
  margin-right: auto;
  ${({ hero, primary }) => `
    ${hero ? 'color: #fff' : primary && `color: ${primary};`}
  `}
`;

const Logo = styled.div`
  ${({ url }) => (url && `background-image: url(${url});`)}
`;

function Layout({
  title,
  highlights,
  footer,
  children,
}) {
  return (
    <StyleContext.Consumer>
      {({ primaryColour, hero, logoUrl }) => (
        <Fragment>
          <PageEnd
            as="section"
            className="hero"
            hero={hero}
            hasLogo={(logoUrl && logoUrl.length)}
          >
            <div className="hero-body">
              <CenteredCol colClassName="is-half hero-heading">
                <Heading
                  className="title is-uppercase has-text-weight-light is-size-5-mobile mb-0"
                  hero={hero}
                  primary={primaryColour}
                >
                  {title}
                </Heading>
                {logoUrl && <Logo url={logoUrl} className="logo" />}
              </CenteredCol>
            </div>
          </PageEnd>
          {highlights()}
          <section className="section">
            <CenteredCol>
              {children}
            </CenteredCol>
          </section>
          {footer && (
            <PageEnd as="footer" className="footer">
              <CenteredCol>
                {footer}
              </CenteredCol>
            </PageEnd>
          )}
        </Fragment>
      )}
    </StyleContext.Consumer>
  );
}

Layout.propTypes = {
  title: PropTypes.string,
  highlights: PropTypes.func,
  footer: propTypes.childrenPropType,
  children: propTypes.childrenPropType,
};

Layout.defaultProps = {
  title: null,
  highlights: () => { },
  footer: null,
  children: null,
};

export default Layout;
