import React, { Fragment, useState } from 'react';
import PropTypes from 'prop-types';
import { toast } from 'react-toastify';
import Layout from './Layout';
import CenteredCol from './CenteredCol';
import { TimesCircle } from '../icons';
import StyledButton from './StyledButton';
import { fetch } from '../utils';

async function doCancel(inviteId, reason, onSuccess = () => { }, onError = () => { }) {
  const resp = await fetch.cancelSlot(inviteId, reason);
  if (!resp || typeof resp === 'string') {
    onError(resp);
  } else {
    if (global?.parent && typeof global.parent.postMessage === 'function') {
      global.parent.postMessage('bookingCancelled', '*');
    }
    onSuccess();
  }
}

function CancelledView({ inviteId, onClick, colors }) {
  const [isCancelled, setIsCancelled] = useState(false);

  if (!inviteId) return null;

  const buttonClasses = 'button is-uppercase is-fullwidth is-small has-text-weight-semibold decision-btn';

  function handleCancel(reason) {
    doCancel(
      inviteId,
      reason,
      () => setIsCancelled(true),
      (message) => {
        toast.error(
          message ||
            'Error cancelling slot, please try again or contact support.'
        );
      }
    );
  }

  return (
    <Layout
      title="Cancellation"
      highlights={() => (
        <Fragment>
          {isCancelled && (
            <section className="section has-background-danger has-text-white">
              <CenteredCol>
                <div className="has-text-centered is-size-4 is-size-6-mobile">
                  <Fragment>
                    <div><TimesCircle /></div>
                    <p>Your appointment has been cancelled</p>
                  </Fragment>
                </div>
              </CenteredCol>
            </section>
          )}
        </Fragment>
      )}
    >
      <div className="section">
        {!isCancelled && (
          <h1 className="title is-uppercase has-text-weight-light is-size-5-mobile has-text-centered">
            Sorry you wish to cancel
          </h1>
        )}
        <div className="content">
          <p className="has-text-weight-semibold is-size-7-mobile">
          {isCancelled ? (
              'You can still book a new appointment, subject to availability of course.'
            ) : (
              // eslint-disable-next-line max-len
              'Please let us know the reason for your cancellation. Don\'t forget, you can still book a new appointment, subject to availability of course.'
            )}
            </p>
          <div className="has-text-centered">
            {!isCancelled && (
              <Fragment>
                <StyledButton
                  type="button"
                  className={`${buttonClasses} mb-3`}
                  onClick={() => handleCancel('UNABLE_TO_ATTEND')}
                  flipColors
                  primary={colors.primary}
                  secondary={colors.secondary}
                >
                  Unable to attend
                </StyledButton>
                <StyledButton
                  type="button"
                  className={`${buttonClasses} mb-3`}
                  onClick={() => handleCancel('NOT_INTERESTED')}
                  flipColors
                  primary={colors.primary}
                  secondary={colors.secondary}
                >
                  No longer interested
                </StyledButton>
                <StyledButton
                  type="button"
                  className={buttonClasses}
                  onClick={() => handleCancel()}
                  flipColors
                  primary={colors.primary}
                  secondary={colors.secondary}
                >
                  Other
                </StyledButton>
                <hr className="my-3" />
              </Fragment>
            )}
            <StyledButton
              type="button"
              className={buttonClasses}
              onClick={onClick}
              primary={colors.primary}
              secondary={colors.secondary}
            >
              Book a new appointment
            </StyledButton>
          </div>
        </div>
      </div>
    </Layout>
  );
}

CancelledView.propTypes = {
  onClick: PropTypes.func,
  colors: PropTypes.shape({
    primary: PropTypes.string,
    secondary: PropTypes.string,
  }),
  inviteId: PropTypes.string,
};

CancelledView.defaultProps = {
  onClick: () => { },
  colors: {},
  inviteId: null,
};

export default CancelledView;
