import React from 'react';
// import PropTypes from 'prop-types';
import Layout from './Layout';

function Page404() {
  return (
    <Layout title="Could not find room">
      <div className="content">
        <p>Sorry something has gone wrong and we&apos;re unable to find the room</p>
      </div>
    </Layout>
  );
}

// Page404.propTypes = {

// };

// Page404.defaultProps = {

// };

export default Page404;
