/* eslint-disable jsx-a11y/control-has-associated-label */
import React from 'react';
import PropTypes from 'prop-types';
import { childrenPropType } from '../utils/propTypes';

function Modal({
  isOpen,
  onClose,
  onOkay,
  children,
  title,
  hideFooter,
  hideOkay,
  hideCancel,
}) {
  return (
    <div className={`modal ${isOpen ? 'is-active' : ''}`}>
      <div
        className="modal-background"
        onClick={onClose}
        onKeyUp={onClose}
        role="button"
        tabIndex="0"
      />
      <div className="modal-card">
        {title && (
          <header className="modal-card-head">
            <p className="modal-card-title">{title}</p>
          </header>
        )}
        {children && <section className="modal-card-body">{children}</section>}
        {!hideFooter && (
          <footer className="modal-card-foot">
            {!hideOkay && (
            <button
              type="button"
              className="button is-success is-small"
              onClick={onOkay}
            >
              Okay
            </button>
            )}
            {!hideCancel && (
            <button
              type="button"
              className="button is-small"
              onClick={onClose}
            >
              Cancel
            </button>
            )}
          </footer>
        )}
      </div>
      <button
        type="button"
        className="modal-close is-large"
        aria-label="close"
        onClick={onClose}
      />
    </div>
  );
}

Modal.propTypes = {
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
  onOkay: PropTypes.func,
  children: childrenPropType,
  title: PropTypes.string,
  hideFooter: PropTypes.bool,
  hideOkay: PropTypes.bool,
  hideCancel: PropTypes.bool,
};

Modal.defaultProps = {
  isOpen: false,
  onClose: () => { },
  onOkay: () => { },
  children: null,
  title: null,
  hideFooter: false,
  hideOkay: false,
  hideCancel: false,
};

export default Modal;
