import React, {
  Fragment,
  useEffect,
  useState,
  useCallback,
  useRef,
} from 'react';
import PropTypes from 'prop-types';
import Webcam from 'react-webcam';
import axios from 'axios';
import config from '../../config/config';
import CenteredCol from '../CenteredCol';

const { api, interviewing } = config;

const videoConstraints = {
  facingMode: 'user',
};

const Lobby = ({
  roomId,
  handleSubmit,
  candidate,
}) => {
  const webcamRef = React.useRef(null);
  const [connectedParticipants, setConnectedParticipants] = useState([]);
  const timer = useRef();

  const checkConnections = useCallback(async () => {
    try {
      const result = await axios.get(
        `${api.middlewareAPIURL}${interviewing.participantCheckPath}?roomId=${roomId}`,
        {},
      );

      if (typeof result !== 'string') {
        setConnectedParticipants(result.data);
        clearTimeout(timer.current);
      }

      timer.current = setTimeout(checkConnections, 10000);
    }
    catch {
      timer.current = setTimeout(checkConnections, 10000);
    }
  }, [roomId]);

  useEffect(() => {
    checkConnections();

    return () => {
      clearTimeout(timer.current);
    };
  }, [checkConnections]);

  return (
    <Fragment>
      <CenteredCol>
        <div className="mt-2">
          <div>
            <div className="is-centered has-text-centered">
              <div className="mb-2 has-text-centered">
                {candidate && (
                  <h4 className="subtitle">
                    {/* eslint-disable-next-line max-len */}
                    {`Welcome ${candidate.applicantProfile.personalDetails.givenName} ${candidate.applicantProfile.personalDetails.familyName}`}
                  </h4>
                )}
              </div>
              <Webcam
                className="webcam-mainview"
                audio={false}
                ref={webcamRef}
                screenshotFormat="image/jpeg"
                videoConstraints={videoConstraints}
              />
              <form onSubmit={handleSubmit}>
                <div className="" style={{ textAlign: 'center' }}>
                  <h4 className="mt-3">Meeting Ready</h4>
                  {connectedParticipants.length === 0 && <p>The interviewer is not here yet</p>}
                  {connectedParticipants.length > 0 && (
                    <p>{`${connectedParticipants[0]} is connected and is waiting`}</p>
                  )}
                  <button className="button is-info mt-1" type="submit">Enter</button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </CenteredCol>
    </Fragment>
  );
};

Lobby.propTypes = {
  roomId: PropTypes.string.isRequired,
  handleSubmit: PropTypes.func,
  candidate: PropTypes.shape(),
};

Lobby.defaultProps = {
  handleSubmit: () => { },
  candidate: {},
};

export default Lobby;
