import axios from 'axios';
import config from '../config/config';

const { api, scheduling } = config;

// eslint-disable-next-line import/prefer-default-export
export async function getSlots(inviteId, groupBy) {
  let slots = [];

  if (!inviteId) return slots;

  let queryStr = `inviteId=${inviteId}`;
  if (groupBy) queryStr += `&groupBy=${groupBy}`;

  try {
    const resp = await axios.get(
      `${api.middlewareAPIURL}${scheduling.availableEvents}?${queryStr}`,
      { headers: { 'Content-Type': 'application/json; charset=utf-8' } },
    );

    if (/200|201/.test(resp.status)) slots = resp.data;
  } catch (error) {
    if (error.response && error.response.status === 503) {
      return {
        status: 'SERVICE_UNAVAILABLE',
      };
    }
    return error.message;
  }

  return slots;
}

export async function acceptSlot(inviteId, slotId) {
  if (!inviteId || !slotId) return {};

  try {
    const resp = await axios.post(
      `${api.middlewareAPIURL}${scheduling.slotAccept}`,
      { slotId, inviteId },
      { headers: { 'Content-Type': 'application/json; charset=utf-8' } },
    );

    if (/200|201/.test(resp.status)) return resp.data;
  } catch (error) {
    if (error.response && error.response.status === 503) {
      return {
        status: 'SERVICE_UNAVAILABLE',
        message:
          'The service is down for scheduled maintaince. Please try again later.',
      };
    }
    return error.message;
  }

  return {};
}

export async function cancelSlot(inviteId, reason = 'OTHER') {
  if (!inviteId) return false;

  try {
    const resp = await axios.delete(
      `${api.middlewareAPIURL}${scheduling.slotCancel}?inviteId=${inviteId}&reason=${reason}`,
      { headers: { 'Content-Type': 'application/json; charset=utf-8' } }
    );

    if (/200|201/.test(resp.status)) return true;
  } catch (error) {
    if (error.response && error.response.status === 503) {
      return 'The service is down for scheduled maintaince. Please try again later.';
    }
  }

  return false;
}

export async function noSlotReport(inviteId) {
  if (!inviteId) return false;

  try {
    const resp = await axios.post(
      `${api.middlewareAPIURL}${scheduling.noSlotPath}?inviteId=${inviteId}`,
      {},
      { headers: { 'Content-Type': 'application/json; charset=utf-8' } },
    );

    if (/200|201/.test(resp.status)) return resp.data;
  } catch (error) {
    if (error.response && error.response.status === 503) {
      return 'SERVICE_UNAVAILABLE';
    }
  }
  return false;
}
