import React, {
  useState,
  useEffect,
  useRef,
  useCallback,
} from 'react';
import PropTypes from 'prop-types';
import Video from 'twilio-video';
import Participant from './Participant';
import CenteredCol from '../CenteredCol';
import LocalParticipant from './LocalParticipant';
import Loading from '../Loading';

const Room = ({
  roomName,
  token,
  handleLogout,
}) => {
  const isMounted = useRef();
  const [isLoading, setIsLoading] = useState(false);
  const [room, setRoom] = useState();
  const [participants, setParticipants] = useState([]);

  const participantConnected = useCallback((participant) => {
    setParticipants((prevParticipants) => [...prevParticipants, participant]);
  }, []);

  const participantDisconnected = useCallback((participant) => {
    setParticipants((prevParticipants) => prevParticipants.filter((p) => p !== participant));
  }, []);

  useEffect(() => {
    isMounted.current = true;
    setIsLoading(true);

    Video.connect(token, {
      audio: true,
      video: true,
      name: roomName,
    }).then((roomObj) => {
      if (isMounted.current) {
        setRoom(roomObj);
        roomObj.on('participantConnected', participantConnected);
        roomObj.on('participantDisconnected', participantDisconnected);
        roomObj.participants.forEach(participantConnected);
        setIsLoading(false);
      }
    });

    return () => {
      isMounted.current = false;
      setRoom((currentRoom) => {
        if (currentRoom && currentRoom.localParticipant.state === 'connected') {
          currentRoom.localParticipant.tracks.forEach((trackPublication) => {
            trackPublication.track.stop();
          });
          currentRoom.disconnect();
          return null;
        }
        return currentRoom;
      });
    };
  }, [participantConnected, participantDisconnected, roomName, token]);

  if (isLoading) return <Loading />;

  return (
    <CenteredCol>
      <div className="room">
        <div className="is-centered has-text-centered">
          <div className="call-controls my-3 mx-3">
            <h4 className="subtitle mb-0">
              {participants.length ? 'Connected to interview' : 'Waiting for interviewer'}
            </h4>
            <button
              type="button"
              className="button is-danger"
              onClick={handleLogout}
            >
              Disconnect
            </button>
          </div>
          {!participants.length ? (
            <LocalParticipant room={room} className="webcam-mainview" />
          ) : (
            <div className="meeting-view">
              {participants.map((participant) => (
                <Participant
                  key={participant.sid}
                  data={participant}
                  className="room-participant-webcam"
                />
              ))}
              <LocalParticipant room={room} className="webcam-preview" />
            </div>
          )}
        </div>
      </div>
    </CenteredCol>
  );
};

Room.propTypes = {
  roomName: PropTypes.string.isRequired,
  token: PropTypes.string.isRequired,
  handleLogout: PropTypes.func,
};

Room.defaultProps = {
  handleLogout: () => { },
};

export default Room;
